import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  BackButton,
  InputField,
  Logo,
  Spinner,
  WelcomeMessage,
} from '../App/Components'
import {
  OptionsContainer,
  StyledScrollView,
} from '../App/Components/AppContainer'
import { setUserData } from '../App/Util'
import { useParams, useLocation } from '../Router'
import {
  grandUserAccess,
  checkIfCustomerIsExisting,
} from '../Settings/Store/Action'

import { CustomTitleMessage } from './Components/CustomTitleMessage/CustomTitleMessage'
import { ExtendedButtonWithText, FormContainer } from './style'
import { useAppDispatch, useAppSelector } from '../store/hooks'

export const ValidateEmail = () => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      email: '',
    },
  })
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { establishmentId, email } = useParams<{ [key: string]: string }>()
  const location = useLocation()
  const surveyPath = `${establishmentId}/surveys/`
  const surveyCheck = location.pathname.includes(surveyPath)

  const { settings, isAccessGranted, authentication } = useAppSelector(
    (state) => ({
      settings: state.settings.values,
      isAccessGranted: state.settings.isAccessGranted,
      authentication: state.settings.authentication,
    })
  )

  useEffect(() => {
    if (authentication.userEmail && !isAccessGranted) {
      setIsLoading(true)
      if (email) {
        setUserData({ email: email })
      }
      dispatch(
        checkIfCustomerIsExisting(establishmentId, authentication.userEmail)
      ).then(() => setIsLoading(false))
    }
  }, [])

  const onSubmit = (data: any) => {
    setIsLoading(true)
    setUserData({ email: data.email })
    dispatch(checkIfCustomerIsExisting(establishmentId, data.email)).then(() =>
      setIsLoading(false)
    )
  }

  if (surveyCheck) {
    dispatch(grandUserAccess())
    return null
  }

  if (authentication.authState === 'authenticated') {
    dispatch(grandUserAccess())
    return null
  }

  if (typeof settings === 'undefined' || isLoading) {
    return <Spinner />
  }

  const customValidateEmailMessage = t(
    'We can not find your e-mail address in our client database, ' +
      'perhaps you misspelled it? We can not accept any new ' +
      'customers because we are at full capacity.'
  )

  return (
    <StyledScrollView>
      <WelcomeMessage
        customMessage={customValidateEmailMessage}
        isValidateEmail
      />
      <CustomTitleMessage
        isExistingCustomersOnly={
          settings.onlineAppointmentsExistingCustomersOnly
        }
        fontColor={settings.fontColor}
        customMessage={settings.onlineAppointmentsExistingCustomersOnlyMessage}
      />
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}`} />
      <FormContainer>
        <InputField
          inputLabel="Email"
          inputRef="email"
          fontColor={settings.fontColor}
          control={control}
          error={errors.email ? 'This field is required.' : ''}
          rules={{ required: true, pattern: /^\S+@\S+$/i }}
          fieldType="email"
          onSubmit={onSubmit}
        />
      </FormContainer>

      <br />
      <ExtendedButtonWithText
        fontcolor={settings.highlightColor}
        backgroundcolor={settings.backgroundColor}
        onPress={handleSubmit(onSubmit)}
        hoversensitive
      >
        {t('Confirm')}
      </ExtendedButtonWithText>
      <OptionsContainer isHidePreviousAppointments />
    </StyledScrollView>
  )
}
