import styled from 'styled-components/native'

import { ProximaNova } from '../../../../../App/Components'

export const EmailHeader = styled(ProximaNova)<{
  color: string
}>`
  text-align: center;
  align-self: center;
  font-weight: 700;
  color: ${({ color }) => color || '#fff'};
  padding: 0 1.5em;
  margin: 0 auto 1.5em;
  margin-top: ${({ extraMargin }) => (extraMargin ? 1.5 : 0)}em;
`

export const EmailSubtitle = styled(ProximaNova)`
  text-align: center;
  align-self: center;
  font-style: italic;
  color: rgba(51,51,51,0.6);
  padding: 0 1.5em;
  margin: 0 auto 1.5em;
  margin-top: ${({ extraMargin }) => (extraMargin ? 1.5 : 0)}em;
`