import { IPreviousAppointment } from '../../../PreviousAppointments/IPreviousAppointment'
import { IMenuActivity } from '../../../Menu/Store/IMenuActivity'
import { ICustomerBlock } from '../../../Appointment/Client/interface/ICustomerBlock'

type TPreviousAppointmentActivity = {
  type: 'prevAppointment'
  payload: IPreviousAppointment
}

type TNewAppointmentActivity = {
  type: 'newAppointment'
  payload: IMenuActivity[][]
}

export const transformToCustomerBlock = (
  data: TNewAppointmentActivity | TPreviousAppointmentActivity
): ICustomerBlock[] => {
  if (data.type === 'newAppointment') {
    return data.payload.map((oneCustomerBlock) => ({
      blocks: oneCustomerBlock.map((activity) => ({
        activity_id: activity.id,
        category_id: activity.categoryId,
        person_id: activity.personSelected ? activity.personSelected.id : null,
      })),
    }))
  }
  return data.payload.activities.map((activity) => ({
    blocks: [
      {
        activity_id: activity.id,
        category_id: activity.categoryId,
        person_id: activity.person.isPreferred ? activity.person.id : null,
      },
    ],
  })
  
  
  
  
  )
}
