import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useParams } from '../Router'
import {
  BackButton,
  DefaultButtonWithText,
  Logo,
  WelcomeMessage,
} from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { removeUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'
import { getUserData, setUserData } from '../App/Util'
import { initialUserData } from '../App/Util/localStorageHelper/UserData/IUserData'
import { setAuthState } from '../Settings/Store/Action'

type Props = {
  token: string
}

export const Account: React.FC<Props> = ({ token }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const dispatch = useAppDispatch()
  const settings = useAppSelector((state) => state.settings.values)
  const { backgroundColor, fontColor } = settings
  const { email } = getUserData()

  const logOutUser = () => {
    removeUserToken(establishmentId, email)
    setUserData(initialUserData)
    dispatch(setAuthState('anonymous'))
    history.push(`/${establishmentId}`)
  }
  return (
    <StyledScrollView>
      <WelcomeMessage />
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}`} />
      <DefaultButtonWithText
        onPress={() => history.push(`/${establishmentId}/account-info`)}
        fontcolor={fontColor}
        backgroundcolor={backgroundColor}
      >
        {t('manage my profile')}
      </DefaultButtonWithText>
      <DefaultButtonWithText
        onPress={() =>
          history.push(`/${establishmentId}/previous-appointments`)
        }
        fontcolor={fontColor}
        backgroundcolor={backgroundColor}
      >
        {t('Manage my appointments')}
      </DefaultButtonWithText>
      <DefaultButtonWithText
        onPress={() => logOutUser()}
        fontcolor={fontColor}
        backgroundcolor={backgroundColor}
      >
        {t('Log out')}
      </DefaultButtonWithText>
    </StyledScrollView>
  )
}
