import { Reducer } from 'redux'

import {
  ENABLE_WAITING_QUEUE,
  GET_SETTINGS_SUCCESS,
  GRAND_USER_ACCESS,
  SET_CUSTOMER_DOES_NOT_EXIST,
  SET_CUSTOMER_EXISTS,
  SET_CUSTOMER_SPECIFIC_DEPOSIT,
  SETTINGS_LOADING,
  SETTINGS_LOADING_FAILURE,
  SET_AUTH_STATE,
  REMOVE_USER_ACCESS,
} from './Action'
import { ISettingsState } from './ISettingsState'

const initialState: ISettingsState = {
  loading: false,
  error: false,
  isAccessGranted: false,
  values: undefined,
  inWaitingQueue: false,
  user: {
    isCustomerSpecificDeposit: false,
  },
  authentication: {
    authState: 'anonymous',
  },
}
export const reducer: Reducer<ISettingsState> = (
  state: ISettingsState = initialState,
  action
): ISettingsState => {
  switch (action.type) {
    case SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        values: action.values,
        inWaitingQueue: false,
      }
    case SET_CUSTOMER_EXISTS:
      return {
        ...state,
        isCustomerExisting: true,
        isAccessGranted: true,
        customerSessionMail: action.payload.email,
      }
    case SET_CUSTOMER_DOES_NOT_EXIST:
      return {
        ...state,
        isCustomerExisting: false,
        isAccessGranted: !state.values.onlineAppointmentsExistingCustomersOnly,
      }
    case SETTINGS_LOADING_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case ENABLE_WAITING_QUEUE:
      return {
        ...state,
        inWaitingQueue: true,
      }
    case GRAND_USER_ACCESS:
      return {
        ...state,
        isAccessGranted: true,
      }
    case REMOVE_USER_ACCESS:
      return {
        ...state,
        isAccessGranted: false,
      }
    case SET_CUSTOMER_SPECIFIC_DEPOSIT:
      return {
        ...state,
        user: {
          isCustomerSpecificDeposit: true,
        },
      }
    case SET_AUTH_STATE:
      return {
        ...state,
        authentication: {
          authState: action.values.authState,
          userToken: action.values.userToken,
          userEmail: action.values.userEmail,
          errorMessage: action.values.errorMessage,
        },
      }
    default:
      return state
  }
}
