import React from 'react'
import { useDispatch } from 'react-redux'

import { AbsoluteSpinner, Tile } from '../../../App/Components'
import { addPersonToAppointment } from '../../../Appointment/store/Actions'
import { useHistory } from '../../../Router'
import { IPerson } from '../../interface/IPerson'

import { IProps } from './IProps'

export const EligiblePerson = ({
  customerActivities,
  persons,
  isPreferredPersonPerActivity,
  establishmentId,
  customerIndex,
  activityId,
}: IProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const eligibleTilePress = (person: IPerson) => {
    if (isPreferredPersonPerActivity && activityId) {
      dispatch(addPersonToAppointment(customerIndex, person, activityId))
      return history.push(`/${establishmentId}/appointment`)
    }
    dispatch(addPersonToAppointment(customerIndex, person, undefined))
    return history.push(`/${establishmentId}/appointment`)
  }

  if (!persons) {
    return <AbsoluteSpinner />
  }

  const filteredPersons = persons.filter((person: IPerson) => {
    if (
      typeof customerActivities === 'undefined' ||
      person.isCabine ||
      !person.isVisibleOnline ||
      !person.isVisibleInCalender
    ) {
      return false
    }

    // Can do one of the activities (For entire appointment)
    for (const activity of customerActivities) {
      if (person.activityIds.includes(activity.id)) {
        return true
      }
    }
    return false
  })

  if (filteredPersons.length === 0) {
    history.replace(`/${establishmentId}/appointment`)
    return null
  }

  const eligiblePersons = filteredPersons.map((person: IPerson) => {
    // If selecting for specific activity make sure the person has knowledge for it
    if (
      typeof activityId !== 'undefined' &&
      !person.activityIds.includes(activityId)
    ) {
      return null
    }

    const newImageUri = person.avatar.slice(6)

    return (
      <Tile
        key={person.id}
        title={person.firstName}
        image={newImageUri}
        link={`/${establishmentId}/appointment`}
        onPress={() => eligibleTilePress(person)}
      />
    )
  })

  return <>{eligiblePersons}</>
}
