import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'
import { useHistory, useParams } from '../../../../../Router'
import {
  BackButton,
  DefaultButtonWithText,
  InputField,
  Logo,
} from '../../../../../App/Components'
import { StyledScrollView } from '../../../../../App/Components/AppContainer'
import { checkUserEmailAndToken } from '../../Client/Client'

import { setAuthState } from '../../../../../Settings/Store/Action'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { IUserData, setUserData } from '../../../../../App/Util'
import {
  getUserToken,
  setUserToken,
} from '../../../../../App/Util/localStorageHelper/userToken/setUserToken'
import { EmailHeader, EmailSubtitle } from './style'
import { TAuthTarget } from '../../TAuthState'

type Props = {
  fontcolor: string
  backgroundcolor: string
  highlight: string
  establishmentId: string
  userData: IUserData
  authTarget: TAuthTarget
}

export const EmailCheck = ({
  fontcolor,
  backgroundcolor,
  highlight,
  establishmentId,
  userData,
  authTarget,
}: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { email, token } = useParams<{ [key: string]: string }>()
  let userToken: string | null = getUserToken(establishmentId, email)

  const { settings, customerSessionMail } = useAppSelector((state) => ({
    settings: state.settings.values,
    customerSessionMail: state.settings.customerSessionMail,
  }))

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      email: userData.email,
    },
  })

  const onSubmit = (data: { email: string }) => {
    if (typeof data.email === 'undefined') return
    const inputMail = data.email
    userToken = getUserToken(establishmentId, inputMail)
    dispatch(setAuthState('checking'))
    checkUserEmailAndToken(
      establishmentId,
      inputMail,
      authTarget,
      token || userToken
    ).then(
      (response) => {
        if (
          response.data.status === 'account_confirmed' &&
          response.data.token
        ) {
          dispatch(setAuthState('authenticated', response.data.token))
          setUserToken(establishmentId, response.data.token, inputMail)
          setUserData({ email: inputMail })
        }
        if (response.data.status === 'no_account_exists') {
          dispatch(setAuthState('authenticated', ''))
          // setUserToken(establishmentId, '', inputMail)
          setUserData({ email: inputMail })
          history.push(`/${establishmentId}`)
        }
        if (response.data.status === 'valid') {
          dispatch(setAuthState('authenticated', userToken, inputMail))
          setUserToken(establishmentId, userToken, inputMail)
          setUserData({ email: inputMail })
        }
        if (response.data.status === 'verification_mail_sent') {
          dispatch(setAuthState('verificationCheck'))
          setUserData({ email: inputMail })
        }
        return null
      },
      (error: AxiosError) => {
        setUserData({ email: inputMail })
        dispatch(
          setAuthState('error', undefined, error.response.data.errors[0])
        )
      }
    )
  }

  if (customerSessionMail) {
    onSubmit({ email: customerSessionMail })
  }

  return (
    <StyledScrollView>
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}`} />
      <EmailHeader color={settings.highlightColor}> {t('Login')} </EmailHeader>
      <EmailSubtitle> {t('Please enter your e-mail address')}</EmailSubtitle>
      <InputField
        inputLabel="Email"
        inputRef="email"
        fontColor={fontcolor}
        control={control}
        error={errors.email ? 'Email not valid' : undefined}
        rules={{ required: true, pattern: /^\S+@\S+$/i }}
        fieldType="email"
        onSubmit={onSubmit}
      />
      <DefaultButtonWithText
        backgroundcolor={backgroundcolor}
        fontcolor={highlight}
        activefontcolor={backgroundcolor}
        hoversensitive
        onPress={handleSubmit(onSubmit)}
        isMaxWidth
      >
        {userToken ? t('Login') : t('Send verification code')}
      </DefaultButtonWithText>
    </StyledScrollView>
  )
}
