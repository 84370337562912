import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components/native'

type Props = {
  isExistingCustomersOnly: boolean
  fontColor: string
  customMessage: string | null
}

const Message = styled.Text<{
  fontcolor: string
}>`
  text-align: center;
  padding: 10px;
  margin-top: 40px;
  color: ${({ fontcolor }) => fontcolor};
  align-self: center;
  font-size: 13px;
`

export const CustomTitleMessage: React.FC<Props> = ({
  fontColor,
  customMessage,
  isExistingCustomersOnly,
}) => {
  const { t } = useTranslation()

  if (!isExistingCustomersOnly) {
    return null
  }

  return (
    <Message fontcolor={fontColor}>
      {t(
        customMessage ||
          'Currently this salon only accepts existing customers. Please verify your email.'
      )}
    </Message>
  )
}
