import React from 'react'

import { useParams, useHistory } from '../Router'
import { Spinner } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'

import { setUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'

export const AccountVerification: React.FC = () => {
  const history = useHistory()
  const { target, token, email, establishmentId } =
    useParams<{ [key: string]: string }>()

  if (target === 'home') {
    setUserToken(establishmentId, token, email)
    history.push(`/${establishmentId}`)
  }

  return (
    <StyledScrollView>
      <Spinner />
    </StyledScrollView>
  )
}
