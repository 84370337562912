import React, { useEffect } from 'react'
import { AxiosError } from 'axios'
import { AuthenticationField } from './components/AuthenticationField/AuthenticationField'
import { getUserData, IUserData, setUserData } from '../App/Util'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useParams } from '../Router'
import { TAuthTarget } from './components/AuthenticationField/TAuthState'
import { setAuthState } from '../Settings/Store/Action'
import { checkUserEmailAndToken } from './components/AuthenticationField/Client/Client'
import { Spinner } from '../App/Components'
import {
  getUserToken,
  setUserToken,
} from '../App/Util/localStorageHelper/userToken/setUserToken'

type Props = {
  target: TAuthTarget
  Component: React.FC<{ token: string }>
}
export const Authenticated: React.FC<Props> = ({ target, Component }) => {
  const { establishmentId, token, email } =
    useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()

  const userData: IUserData = getUserData()
  const userToken: string | null = getUserToken(establishmentId, email)

  const settings = useAppSelector((state) => state.settings)

  const { fontColor, backgroundColor, highlightColor } = settings.values
  const { authState, errorMessage } = settings.authentication

  useEffect(() => {
    if (token && email) {
      setUserToken(establishmentId, token, email)
    }

    if (token && email && authState === 'anonymous') {
      dispatch(setAuthState('checking'))
      checkUserEmailAndToken(establishmentId, email, target, token).then(
        (response) => {
          if (response.data.status === 'valid') {
            setUserData({ email })
            dispatch(setAuthState('authenticated', token))
            setUserToken(establishmentId, token, email)
          }
          if (response.data.status === 'verification_mail_sent') {
            setUserData({ email })
            dispatch(setAuthState('verificationCheck'))
          }
          return null
        },
        (error: AxiosError) => {
          setUserData({ email })
          dispatch(
            setAuthState(
              'error',
              undefined,
              undefined,
              error.response.data.errors[0]
            )
          )
        }
      )
    }
  }, [token, email])

  if (authState === 'checking') return <Spinner />

  if (authState !== 'authenticated') {
    return (
      <AuthenticationField
        userData={userData}
        userToken={userToken}
        fontcolor={fontColor}
        backgroundcolor={backgroundColor}
        highlight={highlightColor}
        authState={authState}
        errorMessage={errorMessage}
        authTarget={target}
      />
    )
  }

  return <Component token={userToken} />
}
